import escapeRegex from './escapeRegex'

const DEFAULT_IMAGE_ID = '383fb098-1c0b-5c18-8199-15d52d5d00c6'

/*
This searches through all wordpress uploads to find an image with a title that
matches the `title` defined in
https://airtable.com/tblwHDkkckho2sGEH/viwl6KgFCouyRtrD7?blocks=hide

If an image is not found, the default image is returned
*/

export default function getVideoSlug(title, images) {
  const pattern = new RegExp(
    escapeRegex(
      title
        // WP (or GraphQL?) encodes '&'
        .replace(/&/g, '&#038;')
    )
      // tolerate either space or dash as a separator
      .replace(/ /g, '[- ]')
  )

  let image = images.find(img => img.title.match(pattern))
  const isImageFound = !!image && !!image.localFile

  if (!isImageFound) {
    image = images.find(img => img.id === DEFAULT_IMAGE_ID)
    console.log({ pattern, isImageFound, image, images })
  }

  return { image, isImageFound }
}
