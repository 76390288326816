import React from 'react'
import { Button } from 'reakit'
import styled from 'styled-components'
import { ifProp, palette as p } from 'styled-tools'
import buttonStretchSvg from '../assets/button--stretch.svg'
import buttonYellowSvg from '../assets/button--yellow.svg'
// import buttonOrangeSvg from '../assets/button--orange.svg'
import playSymbol from '../assets/play-symbol.svg'
import urlToLocal from '../utils/urlToLocal'
import Link from './Link'

const primaryButton = buttonStretchSvg
const inverseButton = buttonYellowSvg

const StyledButton = styled(Button)`
  position: relative;
  display: inline-flex;
  justify-content: center;
  ${'' /* align-self: ${props => (props.isGrouped ? 'auto' : 'center')}; */}

  min-width: ${ifProp('full', '100%', '120px;')};
  max-width: 100%;

  margin: 0.5rem 0.5rem;
  ${'' /* the Box doesn't know its content size… */}
  padding: 2rem 3.5rem 2rem 1rem;
  background-color: transparent;
  background-image: url("${ifProp('inverse', inverseButton, primaryButton)}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: ${props =>
    props.inverse ? p('inverseText', 0) : p('primaryText', 0)};

  transform: translateY(0);
  transition: 100ms all;
  white-space: normal;
  line-height: 1.4;
  font-family: OpenDyslexicBold;
  font-weight: bolder;

  @media print {
    display: none;
  }

  &:hover {
    text-decoration: none;
  }
  /* specificity hack to beat a:-webkit-any-link:active */
  &&:active {
    transform: translateY(2px);
    color: white;
  }


  &:before {
    display: ${ifProp('showNext', 'inline-block', 'none')};
    content: "Next: ";
    padding-right: 0.5em;
  }

  &:after {
    position: absolute;
    top: calc(50% - 10px);
    left: inherit;
    right: 1rem;
    display: inline-block;
    content: "";
    width: 20px;
    height: 20px;
    background: no-repeat center right url("${playSymbol}");
  }

  @media (min-width: 640px) {
    flex-flow: row;
    ${'' /* flex-wrap: wrap; */}
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0.5rem;
    max-width: auto;
  }
`

/**
 * Any Button added via the block editor which has the class `wp-block-button`
 *
 * @param {Array} parentClasses
 * @param {boolean} inverse
 * @param {boolean} full
 */
const CallToAction = ({
  to,
  children,
  showNext,
  parentClasses,
  inverse,
  full,
}) => {
  const href = urlToLocal(to)
  const isExternal = /^(mailto|https?)/.test(href)
  const component = isExternal ? 'a' : Link
  // const next = showNext ? <span key="next">Next:&nbsp;</span> : null
  // const isGrouped = (parentClasses || []).includes('inverse')
  const isGrouped = (parentClasses || []).includes('inverse')
  full = (parentClasses || []).includes('full')
  inverse = inverse || (parentClasses || []).includes('inverse')

  return (
    <StyledButton
      use={component}
      to={href}
      href={isExternal ? href : null}
      // children={[next, <span key="children">{children}</span>]}
      children={children}
      parentClasses={parentClasses}
      isGrouped={isGrouped}
      showNext={showNext}
      inverse={inverse}
      full={full}
    />
  )
}

/**
 * Helper function for use in parseGutenberg
 */
export const createCallToAction = (domNode, debug = false) => {
  const { attribs, children, parent } = domNode
  const classNames = ((attribs && attribs.class) || '').split(' ') || []
  const href = urlToLocal(children[0].attribs.href)
  const aTag = children[0]
  const text = (aTag.children || [])
    // sometimes the first child is `<meta charset="utf-8">` (esp if the content is copy/pasted)
    .filter(node => node.type === 'text')
    .map(node => node.data)
    .join(' ')
  const parentClasses =
    ((parent && parent.attribs.class) || '').split(' ') || []
  if (debug) console.log({ href, text, parentClasses, parent })

  return (
    <CallToAction
      to={href}
      showNext={classNames.includes('next')}
      inverse={classNames.includes('inverse')}
      parentClasses={parentClasses}
    >
      {text}
    </CallToAction>
  )
}

export default CallToAction
