import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Tag from "../Tag"
import Thumbnail from "../Thumbnail"
import VideoMap from "../shortcodes/VideoMap"
import closeIcon from "../../assets/closeIcon--white.svg"
import useQueryString from "../../utils/useQueryString"

const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: 70%;
  grid-gap: 1rem;
  justify-content: center;

  padding-left: 0;
  list-style-type: none;

  @media (min-width: 414px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Tags = styled.ul`
  display: inline-block;
  padding-left: 0;
`

const CloseIcon = styled.img`
  width: 2rem;
  height: auto;
  margin-left: 0.5rem;
`

const VideoList = () => {
  const data = useStaticQuery(graphql`
    query AllAirtable {
      allAirtable {
        nodes {
          id
          data {
            community
            description
            duration
            tags
            title
            url
          }
        }
      }

      allWordpressWpMedia {
        nodes {
          id
          title
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1280) {
                src
                originalName
                originalImg
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                src
              }
            }
            name
            publicURL
            sourceInstanceName
            relativeDirectory
          }
        }
      }
    }
  `)

  const allTags = data.allAirtable.nodes.reduce((acc, node) => {
    const tags = node.data.tags
      .split(',')
      .filter(t => t.search(/ ?(interplay|ndis)/i))
      .map(t => t.trim())
    return [...acc, ...tags]
  }, [])

  const [activeTag] = useQueryString('tag')

  const { nodes } = data.allWordpressWpMedia

  return (
    <>
      {!activeTag && (
        <>
          <VideoMap />

          <h2>Showing stories from all communities</h2>
        </>
      )}

      {activeTag && (
        <>
          <h2>
            Showing stories about{' '}
            <Tag>
              {activeTag}
              <a href="/stories-from-the-community/">
                <CloseIcon
                  src={closeIcon}
                  alt="Show all stories"
                  title="Show all stories"
                  role="button"
                />
              </a>
            </Tag>
            .
          </h2>
          <p>
            <a href="/stories-from-the-community/">Show all stories.</a>
          </p>
        </>
      )}

      <StyledUl>
        {data.allAirtable.nodes
          .filter(({ data }) => {
            if (!activeTag) return true
            return data.tags.includes(activeTag)
          })
          .map(({ id, data }) => (
            <Thumbnail key={id} id={id} data={data} images={nodes} />
          ))}
      </StyledUl>

      <h2>See videos about…</h2>

      <Tags>
        {/*
        {activeTag && (
          <>
            <Tag>
              <a href="/stories-from-the-community/">all</a>
            </Tag>,{" "}
          </>
        )}
        */}
        {allTags
          .reduce((acc, tag) => {
            // not the active tag
            if (tag === activeTag) return acc
            // unique
            if (acc.includes(tag)) return acc
            return [...acc, tag]
          }, [])
          .map((tag, idx, all) => (
            <Tag key={`${idx}-${tag}`}>
              <a
                href={`/stories-from-the-community/?tag=${encodeURIComponent(
                  tag
                )}`}
                className="u-tdn"
              >
                {tag}
              </a>
            </Tag>
          ))}
      </Tags>
    </>
  )
}

export default VideoList
