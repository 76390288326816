/*
https://stackoverflow.com/a/3561711/2586761

The function linked above is insufficient. It fails to escape ^ or $ (start and
end of string), or -, which in a character group is used for ranges.

While it may seem unnecessary at first glance, escaping - (as well as ^) makes
the function suitable for escaping characters to be inserted into a character
class as well as the body of the regex.

Escaping / makes the function suitable for escaping characters to be used in a
JS regex literal for later eval.

As there is no downside to escaping either of them it makes sense to escape to
cover wider use cases.

escapeRegex(something-something-something)
> something\-something\-something
*/
export default function escapeRegex(s) {
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}
