import React from "react"
import styled, { keyframes } from "styled-components"
import { palette as p } from "styled-tools"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const bounce = keyframes`
  0%, 100% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.0);
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  height: ${props => (props.height ? `${props.height}px` : "auto")};
  z-index: 0;
`

const Spinner = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);

  width: 50px;
  height: 65px;
  text-align: center;

  animation: ${rotate} 2s infinite linear;
  opacity: 0.8;
`

const Dot1 = styled.div`
  position: absolute;
  top: 0;
  display: inline-block;
  width: 80%;
  height: 80%;
  border: 6px solid #bbb;
  border-width: 6px 10px 8px 9px;
  border-radius: 100%;

  animation: ${bounce} 2s infinite ease-in-out;
`

const Dot2 = styled(Dot1)`
  top: 20px;
  bottom: 0;
  /* animation-delay: -666ms; */
  animation-delay: -333ms;
`

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 1rem;
  font-family: OpenDyslexicBold, arial, sans-serif;
  font-size: 2rem;
  color: ${p("primary")};
`

const Loader = ({ width, height }) => (
  <Wrapper width={width} height={height}>
    <Spinner>
      <Dot1 />
      <Dot2 />
    </Spinner>
    <Text>Loading...</Text>
  </Wrapper>
)

export default Loader
