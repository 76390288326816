import React from 'react'
import styled from 'styled-components'
import Tooltip from '../Tooltip'

const StyledDiv = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: aliceblue;
`

/**
 * Displays a callout describing the function of the Info popups throughout the site.
 */
const InfoCallout = () => {
  return (
    <StyledDiv>
      <p>
        When you see the{' '}
        <Tooltip definition="Technical terms and more information will be displayed in here!">
          information symbol
        </Tooltip>
        , click on it for technical terms or more information. Try it now!
      </p>
    </StyledDiv>
  )
}

export default InfoCallout
