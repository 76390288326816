import { graphql } from 'gatsby'
import parse from 'html-react-parser'
/* eslint-disable react/prop-types */
import React from 'react'
import PaddedBox from '../components/PaddedBox'
import SEO from '../components/seo'
import Layout from '../layout/Layout'
import { parseGutenburg } from '../utils/parseGutenburg'

export default function Template({ location, data }) {
  const { title, slug } = data.wordpressPage
  const headerColour = 'orange'
  let parsedTitle = parse(title)

  const images = data.allWordpressWpMedia.nodes
  const parsed = parseGutenburg(data.wordpressPage, images)
  const isHomepage = slug === 'home'

  return (
    <Layout isHome={location.pathname === '/'}>
      <SEO title={parsedTitle} />

      <PaddedBox className={slug}>
        {!isHomepage && (
          <div className={`slab slab--${headerColour} section-inverted`}>
            <div className="max-w-default mx-auto px-6">
              <h1>{parsedTitle}</h1>
            </div>
          </div>
        )}

        <div className="parsed">{parsed}</div>
      </PaddedBox>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      menu_order
      link
      date
      content
      slug
      status
      template
      title
      type
      wordpress_id
      wordpress_parent
      # acf {
      #   header_colour
      # }
    }

    allWordpressWpMedia {
      nodes {
        id
        title
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              originalName
              originalImg
              ...GatsbyImageSharpFluid_withWebp
            }
            original {
              src
            }
          }
          name
          publicURL
          sourceInstanceName
          relativeDirectory
        }
      }
    }
  }
`
