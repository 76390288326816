import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import useWindowWidth from "../../utils/useWindowWidth"
import MapSmall from "../MapSmall"
import MapLarge from "../MapLarge"

const MapWrapper = styled.div`
  margin: 0 -1rem;
`

const handleClick = communitySlug => () => {
  console.log({ communitySlug })
  navigate(communitySlug)
}

const VideoMap = () => {
  const windowWidth = useWindowWidth()

  return (
    <MapWrapper>
      {windowWidth < 425 && <MapSmall handleClick={handleClick} />}
      {windowWidth >= 425 && <MapLarge handleClick={handleClick} />}
    </MapWrapper>
  )
}

export default VideoMap
