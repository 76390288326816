import Img from 'gatsby-image'
import React from 'react'

/**
 * https://www.gatsbyjs.com/plugins/gatsby-image/#art-directing-multiple-images
 *
 * * > Note, due to a limitation of GraphiQL, you can not currently use [...] fragments in the GraphiQL IDE.
 * https://github.com/gatsbyjs/gatsby/blob/3c0eb1e46b/packages/gatsby-image/README.md
 */
export const Image = ({ image, wpAttribs, verbose }) => {
  if (verbose) console.log(`[Image] ${wpAttribs.src}`, image, wpAttribs)
  return (
    <Img
      fluid={image.localFile.childImageSharp.fluid}
      alt={wpAttribs.alt}
      // className is rendered on div.gatsby-image-wrapper
      className={wpAttribs.class}
    />
  )
}

export default Image
