// import React from "react"
import styled from 'styled-components'
import dotLineGrey from '../assets/dot-line--grey.svg'
import dotLinePurple from '../assets/dot-line--purple.svg'

/**
 * Dotted Horizontal Rule
 *
 * Inserted via the Block Editor "Insert Separator"
 *
 * ```html
 * <hr clas="wp-block-separator" />
 * ```
 */
const Line = styled.hr`
  margin: ${props => (props.spacious ? '1.5rem 0' : '1rem 0')};
  border: none;

  &:after {
    content: "";
    position: relative;
    bottom: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: 10px;
    background: repeat-x bottom left url("${props =>
      props.grey ? dotLineGrey : dotLinePurple}");
  }
`

export default Line
