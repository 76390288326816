// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f
import qs from 'query-string'
import isBrowser from './isBrowser'

export const setQueryStringWithoutPageReload = qsValue => {
  if (!isBrowser()) return

  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue

  window.history.pushState({ path: newurl }, '', newurl)
}

export const setQueryStringValue = (
  key,
  value,
  queryString = isBrowser() ? window.location.search : ''
) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: value })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

export const getQueryStringValue = (
  key,
  queryString = isBrowser() ? window.location.search : ''
) => {
  const values = qs.parse(queryString)
  return values[key]
}
