import React from 'react'
import useScript from '../../utils/useScript'

const Survey = () => {
  // Needed to get the iframe height correct
  useScript("https://static.airtable.com/js/embed/embed_snippet_v1.js")

  return (
    <iframe
      className="airtable-embed airtable-dynamic-height"
      src="https://airtable.com/embed/shrTSJSKJeKUm3sEO?backgroundColor=orange"
      frameBorder="0"
      scrolling="no"
      width="100%"
      // the Airtable script should set this to fit the content dynamically
      // so that the iframe doesn't scroll
      height="1000"
      title="FIXME-survey"
      style={{ background: "transparent", border: "none" }}
    />
  )
}

export default Survey
