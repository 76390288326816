import React from 'react'
import styled from 'styled-components'
import HearInLanguageButton from '../HearInLanguageButton'

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
  @media (min-width: 768px) {
    flex-direction: column;
    width: 50%;
  }
`
const StyledContent = styled.div`
  min-width: 50%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    width: 100%;
  }
`

const HearInLanguage = () => {
  return (
    <StyledSection>
      <HearInLanguageButton />
      <StyledContent>
        Hear this app spoken in your language! The audio translation is a short
        version of the information.
      </StyledContent>
    </StyledSection>
  )
}

export default HearInLanguage
