import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { List } from 'reakit'
import styled from 'styled-components'
import urlToLocal from '../../utils/urlToLocal'
import CallToAction from '../CallToAction'
import ErrorBoundary from '../ErrorBoundary'

const HealthProfessionalsMenu = () => {
  const data = useStaticQuery(graphql`
    query healthProfessionalsMenu {
      allWordpressWpApiMenusMenusItems(
        filter: { slug: { eq: "health-professionals" } }
      ) {
        edges {
          node {
            name
            count
            items {
              order
              title
              url
            }
          }
        }
      }
    }
  `)

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  `
  const InnerWrapper = styled.div`
    @media (min-width: 768px) {
      margin-left: 2rem;
    }
  `

  /** .wp-block-buttons */
  const BlockButtons = styled(List)`
    display: flex;
    max-width: 100%;
    flex-direction: column;

    @media (min-width: 640px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: auto;
    }
  `

  const mainMenu = data.allWordpressWpApiMenusMenusItems.edges['0'].node

  return (
    <Wrapper>
      <InnerWrapper>
        <BlockButtons>
          <ErrorBoundary>
            {mainMenu.items.map(
              ({ title, url /* , wordpress_children */ }, i) => (
                <CallToAction
                  key={i}
                  to={urlToLocal(url)}
                  inverse
                  isGrouped
                  parentClasses={[]}
                >
                  {title}
                </CallToAction>
              )
            )}
          </ErrorBoundary>
        </BlockButtons>
      </InnerWrapper>
    </Wrapper>
  )
}
export default HealthProfessionalsMenu
