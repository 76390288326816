// import React from 'react'
import styled from 'styled-components'
// import { ifProp, prop } from 'styled-tools'
// import { Box } from 'reakit'
import { palette as p } from 'styled-tools'
import tagSvg from '../assets/button--orange.svg'

export const Tag = styled.li`
  display: inline-block;
  list-style-type: none;
  /*
  padding: 0.25rem;
  border-radius: 4px;
  background-color: ${p('ipOrange')};
  */
  color: white;

  position: relative;
  display: inline-flex;

  /* min-width: 120px; */
  /* max-width: 320px; */

  margin: 0 0.25rem 0.25rem 0;
  padding: 0.25em 0.5em;
  background: no-repeat url(${tagSvg});
  background-size: 100% 100%;

  transform: translateY(0);
  transition: 100ms all;
  white-space: normal;
  line-height: 1.4;

  &:hover {
    text-decoration: none;
  }
  &:active {
    transform: translateY(2px);
  }
`

export default Tag
