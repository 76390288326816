import { Link } from 'gatsby'
// import Img from 'gatsby-image'
import parse from 'html-react-parser'
import React from 'react'
import { createCallToAction } from '../components/CallToAction'
import Image from '../components/Image'
import Line from '../components/Line'
import { createTooltip } from '../components/Tooltip'
import { createVideo } from '../components/Video'
import DisabilitiesMenu from '../components/shortcodes/DisabilitiesMenu'
import HealthProfessionalsMenu from '../components/shortcodes/HealthProfessionalsMenu'
import HearInLanguage from '../components/shortcodes/HearInLanguage'
import HomepageMenu from '../components/shortcodes/HomepageMenu'
import InfoCallout from '../components/shortcodes/InfoCallout'
import NDISWorkersMenu from '../components/shortcodes/NDISWorkersMenu'
import Survey from '../components/shortcodes/Survey'
import VideoCommunity from '../components/shortcodes/VideoCommunity'
import VideoList from '../components/shortcodes/VideoList'
import VideoMap from '../components/shortcodes/VideoMap'
import urlToLocal from './urlToLocal'

const DEFAULT_IMAGE_ID = '383fb098-1c0b-5c18-8199-15d52d5d00c6'

const DEV = process.env.NODE_ENV === 'development'
const VERBOSE = DEV

// The element is replaced only if a valid React element is returned
// https://reactjsexample.com/a-html-to-react-parser/
const noop = <React.Fragment />

/**
 * Parses a monolithic HTML string from the WordPress Gutenberg block editor,
 * returning DOM nodes, converting some of them into components we've defined
 * along the way.
 *
 * This was written well before better solutions were available; recommend a
 * rewrite if major work needs doing. In the meantime, we can progressively
 * improve the readability by using the form:
 *
 * ```javascript
 * import { createMyComponent } from '../MyComponent.js'
 * // ...
 * const isMyComponent = classNames.includes('my-component')
 * if (isMyComponent) parsedNode = createMyComponent(domNode)
 * ```
 *
 * Creating the variable `isMyComponent` makes the code navigable with
 * 'Go to symbol' filtered for 'is'.
 *
 * @see https://github.com/remarkablemark/html-react-parser
 *
 * @param graphqlData ()
 * @param images
 * @returns parsedNode
 */
export function parseGutenburg({ content, slug }, images = []) {
  let components

  components = parse(content, {
    replace: function(domNode) {
      const {
        /** 'tag' or 'text */
        type,
        /** tag name (undefined for type==text) */
        name,
        children,
        data,
        /** attribbutes like: class, alt, title, etc */
        attribs,
      } = domNode

      let parsedNode = domNode

      try {
        /** Drop text nodes with space-only content */
        const isEmpty = type === 'text' && /^\s+$/.test(data)
        if (isEmpty) return noop

        const classNamesString = (attribs && attribs.class) || ''
        /** Array of classNames */
        const classNames = classNamesString ? classNamesString.split(' ') : []

        if (VERBOSE)
          console.groupCollapsed(
            '[parse] %s%s',
            domNode.parent ? ' ⎿ ' : '',
            name ? `${[name, ...classNames].join('.')}` : '(text)'
          )

        if (VERBOSE)
          console.log({
            name: name || '',
            classNames,
            attribs,
            data,
            childrenSummary: (children || []).length
              ? children.map(c => c.name || c.type).join(', ')
              : '(none)',
            domNode,
          })

        const isTooltip = name === 'span' && classNames.includes('tooltip')
        if (isTooltip) parsedNode = createTooltip(domNode, VERBOSE)

        const isColumn = classNames.includes('wp-block-column')
        if (isColumn) {
          attribs.style = '' // remove the glitchy flex-basis element style

          const siblings = domNode.parent.children
          const siblingCount = siblings.filter(n =>
            ((n.attribs || {}).class || '')
              .split(' ')
              .includes('wp-block-column')
          ).length

          // REVIEW: are we using tailwinds?
          attribs.class += ` w-full md:w-1/${siblingCount} px-2 mb-6 border-transparent`
        }

        /** <hr clas="wp-block-separator" /> */
        const isBlockSeparator = classNames.includes('wp-block-separator')
        if (isBlockSeparator) parsedNode = <Line spacious />

        const isVideo = classNames.includes('wp-block-embed-youtube')
        if (isVideo)
          parsedNode = createVideo(domNode, images, DEFAULT_IMAGE_ID, VERBOSE)

        const isLink = name === 'a'
        if (isLink) {
          const href = urlToLocal(attribs.href)
          const isExternal = /^(mailto|tel|https?)/.test(href)
          // only use Link for internal links
          if (!isExternal) {
            parsedNode = (
              <Link
                className={Array.from(domNode.classList || []).join(' ')}
                to={href}
              >
                {children[0].data}
              </Link>
            )
          }

          // from Gatsby source
          const LOCAL_URL = /^\/(?!\/)/
          const isLocal = LOCAL_URL.test(href)
          if (process.env.NODE_ENV !== 'production' && isLocal === isExternal) {
            console.warn(
              `Gatsby reads this as an External link: %o.\n` +
                `We found: isExternal = /^(mailto|https?)/.test(href) %o \n` +
                // eslint-disable-next-line no-useless-escape
                `Gatsby: isLocal = /^\/(?!\/)/.test(href) %o`,
              href,
              isExternal,
              isLocal
            )
          }
        }

        // div.wp-block-button a (dont match `wp-block-buttons` plural!)
        const isCallToAction = classNames.includes('wp-block-button')
        if (isCallToAction) parsedNode = createCallToAction(domNode, VERBOSE)

        const isImg = name === 'img'
        if (isImg) {
          const matches =
            attribs.src.match(
              // not sure why WP adds `maybeDImensions` sometimes? Extract so we can ignore it
              /wp-content\/uploads\/\d{4}\/\d{2}\/(?<basename>.*?)(?<maybeDimensions>-\d+x\d+|-\d+)?.(?<extension>png|je?pg|gif)/
            ) || []
          // eslint-disable-next-line no-unused-vars
          const [match, basename, maybeDimensions, extension] = matches

          let image
          if (basename)
            image = images.find(i => {
              // note that svgs don't have childImageSharp
              if (!i.localFile.childImageSharp) return null
              return (
                // try exact match...
                i.localFile.childImageSharp.fluid.originalName ===
                  `${basename}.${extension}` ||
                // ...fallback to closest match
                i.localFile.childImageSharp.fluid.originalName.includes(
                  basename
                )
              )
            })
          if (image) {
            if (VERBOSE)
              console.log(`[parse] Image: ${attribs.src}`, {
                image,
                attribs,
              })

            parsedNode = (
              <Image
                key={image.id}
                image={image}
                wpAttribs={attribs}
                verbose={VERBOSE}
              />
            )
          } else {
            console.warn(`[parse] image not found: ${attribs.src}`, {
              matches,
              attribs,
            })
          }
        }

        /** Gutenberg renders shortcodes as: `<p>calendly-embed</p>` */
        const isMaybeShortcode =
          (name === 'p' && children.length && children[0].data) || ''
        // eslint-disable-next-line default-case
        switch (isMaybeShortcode) {
          case 'Survey':
            parsedNode = <Survey />
            break
          case 'VideoList':
            console.assert(images.length, 'Missing images!')
            console.log({ images })
            parsedNode = <VideoList />
            break
          case 'VideoMap':
            parsedNode = <VideoMap />
            break
          case 'VideoCommunity':
            console.assert(images.length, 'Missing images!')
            console.log({ images })
            parsedNode = <VideoCommunity slug={slug} />
            break
          case 'HomepageMenu':
            parsedNode = <HomepageMenu />
            break
          case 'HealthProfessionalsMenu':
            parsedNode = <HealthProfessionalsMenu />
            break
          case 'DisabilitiesMenu':
            parsedNode = <DisabilitiesMenu />
            break
          case 'NDISWorkersMenu':
            parsedNode = <NDISWorkersMenu />
            break
          case 'HearInLanguage':
            parsedNode = <HearInLanguage />
            break
          case 'InfoCallout':
            parsedNode = <InfoCallout />
            break
          // continue... (no default case)
        }

        if (VERBOSE) console.groupEnd('[parse] type:%o name:%o', type, name)
        return parsedNode
      } catch (error) {
        console.error(error)
        return domNode
      }
    },
  })

  return components
}
