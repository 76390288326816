import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Thumbnail from "../Thumbnail"

const StyledUl = styled.ul`
  padding-left: 0;
  list-style-type: none;
`

// This shortcode is included in pages created in WordPress, and added to the `stories` menu
const VideoCommunity = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query AllAirtableCommunity {
      allAirtable {
        nodes {
          id
          data {
            community
            description
            duration
            tags
            title
            url
          }
        }
      }

      allWordpressWpMedia {
        nodes {
          id
          title
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1280) {
                src
                originalName
                originalImg
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                src
              }
            }
            name
            publicURL
            sourceInstanceName
            relativeDirectory
          }
        }
      }
    }
  `)

  const { nodes } = data.allWordpressWpMedia

  const communitiesLookup = {
    "groote-eylandt": ["Angurugu, Groote Eylandt", "Angurugu"],
    "tiwi-islands": ["Tiwi", "Tiwi Islands", "Wurrumiyanga"],
    mutitjulu: ["Mutitjulu"],
    ntaria: ["Ntaria"],
    yirrkala: ["Yirrkala"],
  }

  return (
    <StyledUl>
      {data.allAirtable.nodes
        .filter(({ data }) => communitiesLookup[slug].includes(data.community))
        .map(({ id, data }) => (
          <Thumbnail key={id} id={id} data={data} images={nodes} />
        ))}
    </StyledUl>
  )
}

export default VideoCommunity
