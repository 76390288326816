import Img from 'gatsby-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { palette as p } from 'styled-tools'
import playIcon from '../assets/playIcon.svg'
import useWindowWidth from '../utils/useWindowWidth'
import Loader from './Loader/Loader'

const VideoContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const IframeWrapper = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  max-width: 100%;
  z-index: 1; /* raise above loader */
`

const StyledFigure = styled(Img)`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 414px) {
    width: 100%;
  }

  picture:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    background-size: contain;
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    display: block;
    width: 70px;
    height: 70px;
    background: no-repeat center url("${playIcon}");
    background-size: contain;
    transition: all 100ms ease;

    @media (min-width: 425px){
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;
    }
  }
  &:hover:after,
  &:focus:after,
  &:active:after {
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;

    @media (min-width: 425px){
      top: calc(50% - 60px);
      left: calc(50% - 60px);
      width: 120px;
      height: 120px;
    }
  }

  &.image-missing {
    outline: 1px solid ${p('grayscale', 5)};
  }
`

const Duration = styled.span`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 0.125rem 0.75rem;
  border-radius: 4px;
  background-color: ${p('primary')};
  color: white;
  font-weight: bold;
`

const handleVideoPlay = setIsPlaying => event => {
  // TODO: track play
  setIsPlaying(true)
}

/** Styled Youtube video embed */
const Video = ({ url, className, image, title, duration }) => {
  const windowWidth = useWindowWidth()
  const REM = 18
  const MAX_WIDTH = 768
  const width = Math.min(windowWidth, MAX_WIDTH) - 2 * REM
  const height = width * (9 / 16)
  const [isPlaying, setIsPlaying] = useState(null)

  // https://www.youtube.com/watch?v=LX4dra-FhRY&list=PLtiTi_4uIQCH_Td2lc3HVutLEX_eZBsvh&index=12&t=0s
  const id = (url || '').replace(/^.*watch\?v=/, '').replace(/&list=.*/, '')
  // eslint-disable-next-line no-useless-concat
  const src =
    // https://developers.google.com/youtube/player_parameters#autoplay
    `https://www.youtube-nocookie.com/embed/${id}` +
    // requires iframe[allow="autoplay"]
    `?autoplay=1` +
    // rel=0: the player will show related videos that are from the same channel as the video that was just played
    `&rel=0`
  // cc_load_policy=1: causes closed captions to be shown by default, even if the user has turned captions off
  // `&cc_load_policy=1`
  // `&controls=1`
  // `&modestbranding=1`

  return (
    <VideoContainer width="100%" onClick={handleVideoPlay(setIsPlaying)}>
      {isPlaying && <Loader width={width} height={height} />}

      {!isPlaying && (
        <>
          {image && image.localFile && (
            <StyledFigure
              fluid={image.localFile.childImageSharp.fluid}
              width="100%"
              className={className}
              onClick={handleVideoPlay(setIsPlaying)}
              alt={image.title}
            />
          )}
          {duration && <Duration>{duration}</Duration>}
        </>
      )}

      {isPlaying && (
        <IframeWrapper width={`${width}px`} height={`${height}px`}>
          <iframe
            title={title || `Interplay Project: ${id}`}
            width={width}
            height={height}
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </IframeWrapper>
      )}
    </VideoContainer>
  )
}

/** Helper function for parseGutenberg */
export const createVideo = (domNode, images, DEFAULT_IMAGE_ID, debug) => {
  const { children } = domNode
  const iframe = (children || [{}])[0].children.find(c => c.name === 'iframe')
  if (!!iframe) {
    let url = iframe.attribs.src

    const videoId = (url || '').replace(/^.*embed\//, '').replace(/\?.*/, '')
    url = `https://www.youtube.com/watch?v=${videoId}`

    let image = images.find(
      i =>
        i.localFile.childImageSharp && // note that svgs don't have childImageSharp
        i.localFile.childImageSharp.fluid.originalName.indexOf(videoId) > -1
    )
    const isImageFound = !!image
    if (!isImageFound) image = images.find(i => i.id === DEFAULT_IMAGE_ID)

    if (debug) console.log({ image, isImageFound, videoId, url, iframe })

    return (
      <Video
        className={!isImageFound && 'image-missing'}
        image={image}
        url={url}
      />
    )
  }
}

export default Video
