const createVideoSlug = (str = '') => {
  console.assert(!!str, 'str is empty!')

  return encodeURIComponent(
    str
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace('&', 'and')
  )
}

module.exports = {
  createVideoSlug,
}
