import React from 'react'
import Link from './Link'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { palette as p } from 'styled-tools'

import getVideoImage from '../utils/getVideoImage'
const createVideoSlug = require('../utils/createVideoSlug').createVideoSlug

const StyledListItem = styled.li`
  display: flex;
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: ${p('black')};
`

const StyledFigure = styled(Img)`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;

  @media (min-width: 414px) {
    width: 100%;
  }

  &.image-missing {
    outline: 1px solid ${p('grayscale', 5)};
  }
`

const Details = styled.div`
  margin-bottom: 0;
`

const Thumbnail = ({ id, data, images }) => {
  const { title } = data
  const { image, isImageFound } = getVideoImage(title, images)
  if (!image.localFile) console.warn('Missing image.localFile!', { image })

  // http://localhost:8000/___graphql?query=query%20MissingImages%20%7B%0A%20%20allWordpressWpMedia%20%7B%0A%20%20%20%20edges%20%7B%0A%20%20%20%20%20%20node%20%7B%0A%20%20%20%20%20%20%20%20media_details%20%7B%0A%20%20%20%20%20%20%20%20%20%20file%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20localFile%20%7B%0A%20%20%20%20%20%20%20%20%20%20childImageSharp%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20fluid%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20src%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20title%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A&operationName=MissingImages
  return (
    <StyledListItem key={id}>
      <StyledLink to={`/stories/${createVideoSlug(title)}`}>
        {image && image.localFile && (
          <StyledFigure
            fluid={image.localFile.childImageSharp.fluid}
            width="100%"
            className={!isImageFound && 'image-missing'}
            alt={image.title}
          />
        )}
        <Details>
          <p>{data.title}</p>
        </Details>
      </StyledLink>
    </StyledListItem>
  )
}

export default Thumbnail
