import iconClose from '!raw-loader!../assets/icon-close.svg'
import iconInfo from '!raw-loader!../assets/infoIcon.svg'
import React, { useEffect } from 'react'
import { Backdrop, Button, Overlay, Portal } from 'reakit'
import styled from 'styled-components'
import { palette } from '../theme/theme'
import SVG from './SVG'

const StyledDt = styled.span`
  text-decoration: underline;
  color: ${palette.ipBlue};

  &:hover {
    cursor: help;
  }
`

/**
 * Displays a elements with aria-roles for term and definition.
 *
 * In the editor, activate the options on a paragraph and "Edit as HTML"
 * then craft a `span` tag with `class="tooltip"` and set the `title` to
 * the definition that should be displayed:
 *
 * ```html
 * <p>
 *    <p>Demo tooltip:
 *    <span class="tooltip" title="definition">term</span>
 *    .
 * </p>
 * ```
 *
 * After adding the required markup, you can select "Edit Visually" from block options.
 * Compliant terms will be highlighted in the editor, using editor styles:
 *
 * ```css
 * span.tooltip {
 *    display: inline;
 *    background-color: lightblue;
 *    cursor: help;
 * }
 * ```
 *
 * There is a bookmarklet to help generate the markup in ../scripts/tooltip-bookmarklet.js
 *
 * Consider:
 * - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dd
 * - https://www.sarasoueidan.com/blog/accessible-tooltips/
 */
export const Tooltip = ({ children, definition }) => {
  return (
    <Overlay.Container>
      {overlay => (
        <>
          <span>
            <StyledDt role="term" onClick={overlay.show} title={definition}>
              {children}
            </StyledDt>

            <SVG
              onClick={overlay.show}
              svg={iconInfo}
              alt="Info icon"
              width="1em"
              style={{ marginLeft: '0.25em' }}
            />
          </span>

          <TooltipModal overlay={overlay} definition={definition} />
        </>
      )}
    </Overlay.Container>
  )
}

const StyledBackdrop = styled(Overlay.Hide)`
  z-index: 19900420;
`
const StyledOverlay = styled.div`
  border-radius: 0;
  max-height: 100vh;
  max-width: 380px;
  overflow: scroll;
  padding: 5rem 1.8rem 2rem;
  width: 100%;
  z-index: 19900430;
`
const CloseButton = styled(Button)`
  display: block;
  height: auto;
  padding: 0.5rem 0.5rem 0;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`
const StyledP = styled.p`
  font-size: 1.4rem;
`

export const TooltipModal = ({ overlay, definition }) => {
  useEffect(() => {
    if (overlay.visible) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [overlay.visible])

  return (
    <>
      <Backdrop use={[Portal, StyledBackdrop]} {...overlay} />
      <Overlay use={[Portal, StyledOverlay]} {...overlay}>
        <SVG
          svg={iconInfo}
          alt="Info icon"
          width="5em"
          style={{
            position: 'absolute',
            top: '0.5rem',
            left: '0.5rem',
            opacity: 0.2,
          }}
        />

        <CloseButton
          aria-label="close"
          palette="secondary"
          onClick={overlay.hide}
        >
          <SVG svg={iconClose} alt="Close" />
        </CloseButton>

        <StyledP role="definition">{definition}</StyledP>
      </Overlay>
    </>
  )
}

/**
 * Returns a <Tooltip>, where
 *
 * - `term` is the text content of all the `span`'s TEXT children, ignoring any nested tags
 * - `definition` is the `span`'s title
 *
 * @param domNode from html-react-parser#parser
 * @returns JSX <Tooltip />
 */
export const createTooltip = (domNode, debug = false) => {
  const { children, attribs } = domNode

  const term = Object.entries(children)
    .map(([k, v]) => (v.type === 'text' ? v.data : ''))
    .join(' ')

  const definition = attribs.title
  if (debug) console.log('[createTooltip]', { term, definition, domNode })

  return <Tooltip definition={definition}>{term}</Tooltip>
}

export default Tooltip
