import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { List } from 'reakit'
import styled from 'styled-components'
import { palette as p } from 'styled-tools'
import dotOrange from '../../assets/dot--orange.svg'
import urlToLocal from '../../utils/urlToLocal'
import CallToAction from '../CallToAction'
import Link from '../Link'
import HearInLanguage from './HearInLanguage'

const HomepageMenu = () => {
  const data = useStaticQuery(graphql`
    query homepageMenu {
      allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "homepage" } }) {
        edges {
          node {
            name
            count
            items {
              order
              title
              url
              # throws / won't exist if no children exist!
              # wordpress_children {
              #   wordpress_id
              #   title
              #   url
              # }
            }
          }
        }
      }
    }
  `)

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  `
  const InnerWrapper = styled.div`
    @media (min-width: 768px) {
      min-width: 300px;
    }
  `

  const StyledList = styled(List)`
    list-style-image: url(${dotOrange});
    margin-bottom: 2rem;
  `
  const StyledListItem = styled.li`
    margin-left: 1rem;
    padding-left: 0.5rem;
  `
  const StyledLink = styled(Link)`
    color: ${p('black')};
    display: block;
    font-size: 1.2rem;
    line-height: 1.35;
  `

  const mainMenu = data.allWordpressWpApiMenusMenusItems.edges['0'].node

  return (
    <Wrapper>
      <HearInLanguage />

      <InnerWrapper>
        <StyledList>
          {mainMenu.items.map(
            ({ title, url /* , wordpress_children */ }, i) => (
              <StyledListItem key={i} className="mb-2">
                <StyledLink to={urlToLocal(url)}>{title}</StyledLink>
              </StyledListItem>
            )
          )}
        </StyledList>

        <CallToAction to={'/what-is-the-ndis/'} showNext full>
          What is the NDIS?
        </CallToAction>
      </InnerWrapper>
    </Wrapper>
  )
}

export default HomepageMenu
